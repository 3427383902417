

#map-container {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #092321;
}

.map {
    width: 100%;
    height: 100vh;
}

.region-border {
    transition-timing-function: ease-in-out;

    transition: 0.3s;
    opacity: 0;
}

.region-border:hover {
    transition-timing-function: ease-in-out;

    transition: 0.3s;
    
    opacity: 1;
    
}

.region-border.modal-active {
    opacity: 1;
}

.modal-territory-content {
    height: 90%;
    display: flex;
    flex-direction: row;
}



.info-box {
    height: 95%;
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    overflow: auto;
    
}

.description-box {
    height: 95%;
    width: 100%;
    overflow: auto;
    padding-right: .5rem;
}

.info-box::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.description-box::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.modal-territory-content img {
    border: 1px solid black;
    border-radius: .5rem;
    flex: 1;
    max-width: 45%;
    max-height: 35%;
    transform: scale(1, 1);
-ms-transform: scale(1, 1);
-webkit-transform: scale(1, 1);
}

.modal-territory-content h6 {
    font-size: larger;
}