.recap-container {
    margin: 3rem 10rem;
    color: white;
    display: flex;
    flex-direction: column;
}

.recap-container h5 {
    text-align: center;
    padding-bottom: 3rem;
    font-size: 50px;
    font-weight: 300;
}

.recap-container h6 {
    font-size: 30px;
    font-weight: 300;
    padding-bottom: 10px;
}

.recap-content {
    min-height: 300px;
    height: fit-content;
}

.recap-actions {
    width: 100%;
    padding-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.recap-action-buttons {
    gap: 2em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.recap-actions button {
    border: none;
    background-color: inherit;
    font-size: 20px;
    color: #00AA9F;
    cursor: pointer;
    transition-timing-function: ease-in-out;
    transition: 0.3s;
}

.recap-actions button:hover {
    transition-timing-function: ease-in-out;
    transition: 0.3s;
    color: #E2FFFC;
}

@media screen and (max-width: 900px) {
    .recap-container {
        margin: 1rem 5rem;
    }
}

@media screen and (max-width: 370px){
    .recap-container {
        margin: .5;
    }
}
