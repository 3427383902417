.city-infos {
    display: flex;
    flex-direction: column;
}

.city-infos h4 {
    text-align: center;
    padding-bottom: .5rem;
    font-size: 20px;
}

.city-infos h3 {
    text-align: center;
}

.city-infos li {
    font-size: 14px;
}
