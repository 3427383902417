.footer {
    margin: 25px;
    padding-bottom:2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.footer-links a {
    padding: 0 10px 0 10px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
}