.backdrop {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.content {
    height: 95%;
}


.container {
    height: clamp(50%, 300px, 30%);
    width: clamp(50%, 700px, 90%);
    background-color: whitesmoke;
    border-radius: 1rem;
    padding: .5rem;
    padding-bottom: .5rem;
    box-shadow: 0 20px 25px black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


h2 {
    text-align: center;
    font-size: 2rem;
}

.modal-footer {
    display: flex;
    justify-content: center;
}

.modal-button {
    border: none;
    background-color: inherit;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00AA9F;
}

.modal-button:hover {
    transition: 0.2s;
    color: black;
}