.body {
    background-color: #092321;
}

.timeline-content {
    padding: 5rem 0 ;
    margin-bottom: 2rem;
}

.vertical-timeline-element {
    margin: 10rem 0;
}

.vertical-timeline-element-title {
    padding-bottom: 10px;
}

.vertical-timeline-element-subtitle {
    font-weight: 300;
    font-size: 1.1rem;
}

.vertical-timeline-element-date {
    color: white;
    font-weight: 300;
    font-size: 24px !important;
    top: 0 !important;
    
}