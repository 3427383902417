* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    height: 100%;
}

body {
    line-height: 1.7;
    -webkit-font-smoothing: antialiased;
}
img, canvas, svg {
    display: block;
    max-width: 100%;
}
p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
}

body::-webkit-scrollbar {
    width: 3px;
}

body::-webkit-scrollbar-thumb {
    background-color: aliceblue;
    border-radius: 6px;
    border: 3px solid aliceblue;
}

body::-webkit-scrollbar-track {
    background: black;
}
