.nav-bar {
  right: 2rem;
  width: auto;
  height: 10%;
  justify-content: center;
  align-items: center;
  position: fixed;
  margin-top: 1rem;
  display: flex;
  z-index: 999;
  background: rgba(255, 255, 255, 0.075);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.6px);
  -webkit-backdrop-filter: blur(7.6px);
  border: 1px solid rgba(255, 255, 255, 0.09);
}

.nav-links {
  list-style: none;
  padding: 2rem;
}

.nav-links li {
  display: inline-block;
  padding: 0px 20px;
}

.nav-links a {
  text-decoration: none;
  font-size: 1.5rem;
  color: #e2fffc;
  transition-timing-function: ease-in-out;
  transition: 0.3s;
}

.nav-links a:hover {
  transition-timing-function: ease-in-out;
  transition: 0.3s;
  color: #00aa9f;
}

.mobile-menu-nav {
  position: fixed;
  display: flex;
  justify-content: center;
  right: 2rem;
  margin-top: 1rem;
  z-index: 999;
}

.mobile-overlay {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  z-index: 900;
  inset: 0 0 0 50%;
  background: rgba(255, 255, 255, 0.075);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.6px);
  -webkit-backdrop-filter: blur(7.6px);
  border: 1px solid rgba(255, 255, 255, 0.09);
}

@media screen and (max-width: 900px) {

  .nav-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10rem 3rem;
    gap: 2rem;
  }
}

@media screen and (max-width: 400px){
  .nav-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10rem 0;
    gap: 2rem;
  }
}