body {
    height: 100%;
    overflow-x: hidden;
}

.home-screen::-webkit-scrollbar-track {
    background: black !important;
}



#title {
    color: white;
    background-image: url("../assets/forestHomeImg1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

#title h1 {
    text-align: center;
    font-size: 8rem;
    font-weight: 300;
}

#title h4 {
    margin-top: 4rem;
    text-align: center;
    font-weight: 300;
    font-size: 1.3rem;
}

@media screen and (max-width: 900px) {
    #title h1 {
        font-size: 4rem;
    }

    #title h4 {
        font-size: 1rem;
    }
}
